import { withTranslation, WithTranslation } from 'next-i18next';
import React, { Children, cloneElement, FC, isValidElement, ReactNode, useRef } from 'react';
import { useSafeLayoutEffect } from '@chakra-ui/react-use-safe-layout-effect';
import { isBrowser } from '@/constants/global';
import imagesLoaded from 'imagesloaded';
import type MasonryLayout from 'masonry-layout';

import styles from './PostsContainer.module.scss';

const Masonry = isBrowser ? require('masonry-layout') : null;
const elementClassName = `.${styles.postsContainer}`;
const columnWidthClassName = `.${styles.postsContainerGridSizer}`;
const gutterClassName = `.${styles.postsContainerGutterSizer}`;
const itemSelectorClassName = `.${styles.postsContainerItem}`;

interface IPostsContainerProps extends WithTranslation {
	children?: ReactNode;
	emptyImage?: string;
	/**
	 * This props are literal strings because translations keys are also literal strings
	 * todo: refactor this when we refactor translation keys to something meaningful
	 */
	emptyMessage?: 'you-havent-liked-or-posted-an' | 'no-exhibitions-to-show-do-you';
}

const PostsContainer: FC<IPostsContainerProps> = ({ children = [], emptyImage, emptyMessage, t }) => {
	const masonryRef = useRef<MasonryLayout>(null);

	useSafeLayoutEffect(() => {
		if (Masonry && Children.count(children) > 0) {
			let masonry: MasonryLayout;

			if (masonryRef.current) {
				masonry = masonryRef.current;
			} else {
				masonry = new Masonry(elementClassName, {
					transitionDuration: 0,
					columnWidth: columnWidthClassName,
					gutter: gutterClassName,
					itemSelector: itemSelectorClassName,
					percentPosition: true,
				}) as MasonryLayout;

				masonryRef.current = masonry;
			}

			masonry.reloadItems();

			imagesLoaded(elementClassName, () => {
				masonry.layout();
			});
		}
	}, [children]);

	if (Children.count(children) === 0) {
		return (
			<div className={styles.postsContainerEmpty}>
				<div className={styles.postsContainerEmptyItem}>
					<img className={styles.postsContainerEmptyImage} src={emptyImage} />
					<p className={styles.postsContainerEmptyDescription}>{emptyMessage && t(emptyMessage)}</p>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.postsContainer}>
			<div className={styles.postsContainerGridSizer}></div>
			<div className={styles.postsContainerGutterSizer}></div>

			{Children.map(
				children,
				(child) =>
					isValidElement(child) &&
					cloneElement(child, {
						// @ts-ignore
						className: styles.postsContainerItem,
					})
			)}
		</div>
	);
};

export default withTranslation()(PostsContainer);
