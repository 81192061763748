import Button from '@/components/core/Button/Button';
import { FormInput } from '@/components/core/FormInput/FormInput';
import Section from '@/components/core/Section/Section';
import { EMAIL_REGEX } from '@/components/shared/forms/form-validation.utils';
import { useClient } from '@/components/shared/utilities/AppProvider/AppProvider';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styles from './SubscribeForm.module.scss';

interface ISubscribeForm extends FieldValues {
	email: string;
}

export const SubscribeForm: FC = () => {
	const { t } = useTranslation();
	const client = useClient();
	const [submitted, setSubmitted] = useState(false);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors, isSubmitting },
	} = useForm<ISubscribeForm>({ mode: 'onBlur' });

	const onSubmit = async (data) => {
		try {
			await client.create('newsletter/subscription', {
				...data,
			});
			setSubmitted(true);
		} catch (e) {
			setError(
				'email',
				{
					type: 'custom',
					message: 'Something went wrong',
				},
				{ shouldFocus: false }
			);
		}
	};

	return (
		<Section fullWidth color="Dimmed">
			{submitted ? (
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<div className={styles.subscribed}>{`${t('thank-you-for-subscribing-to-t')}!`}</div>
					</div>
				</div>
			) : (
				<form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.container}>
						<div className={styles.title}>{t('newsletter')}</div>
						<div className={styles.email}>
							<FormInput
								className={styles.input}
								type="text"
								placeholder={t('enter-email')}
								showError={Boolean(errors.email)}
								{...register('email', {
									required: 'Required',
									pattern: {
										value: EMAIL_REGEX,
										message: t('invalid-email-address'),
									},
								})}
							/>
							{errors.email && <div>{errors.email?.message}</div>}
						</div>
						<div className={styles.submitWrapper}>
							<Button className={styles.submit} color={Button.color.PRIMARY} submit block loading={isSubmitting}>
								{t('subscribe-to-newsletter')}
							</Button>
						</div>
					</div>
				</form>
			)}
		</Section>
	);
};
