import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './SponsorsSection.module.scss';
import Section from '@/components/core/Section/Section';

const SponsorsSection: FC = () => {
	const { t } = useTranslation();

	return (
		<Section color="Dimmed">
			<div className={styles.sponsors}>
				<div className={styles.sponsorsRow}>
					<div className={styles.sponsorsColumn}>
						<img src={'/assets/svgs/Flag_of_Europe 1.svg'} alt="EU Flag" />
						<span>{t('european-union')}</span>
					</div>
					<div className={styles.sponsorsColumn}>
						<img src={'/assets/svgs/esif-logo.svg'} alt="Esif Logo" />
					</div>
					<div className={styles.sponsorsColumn}>
						<p className={styles.sponsorsDisclaimer}>{t('european-financing-disclaimer')}</p>
					</div>
					<div className={styles.sponsorsColumn}>
						<img src={'/images/HAMAG-Bicro-logo.png'} alt="HAMAG Bicro Logo" />
					</div>
				</div>
			</div>
		</Section>
	);
};

export default SponsorsSection;
