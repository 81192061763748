import { withTranslation, WithTranslation } from 'next-i18next';
import Link from 'next/link';
import { Component } from 'react';

import styles from './EditBar.module.scss';

interface IEditBarProps extends WithTranslation {
	for?: {
		id: string;
	};
}

class EditBar extends Component<IEditBarProps> {
	render() {
		const { t } = this.props;
		// const IconEdit = require('babel!svg-react!../../assets/icons/edit.svg?name=IconEdit');
		// const IconDelete = require('babel!svg-react!../../assets/icons/delete.svg?name=IconDelete');

		return (
			<div className={styles.editBar}>
				<Link
					className={styles.editBarLink}
					href={{ query: { open: 'edit-story', storyId: this.props.for?.id } }}
					shallow={true}
				>
					<img src="/assets/icons/edit.svg" className={styles.editBarLinkIcon} alt="edit" />

					{t('edit')}
				</Link>
				<Link
					className={styles.editBarLink}
					href={{ query: { open: 'delete-story', storyId: this.props.for?.id } }}
					shallow={true}
				>
					<img src="/assets/icons/delete.svg" className={styles.editBarLinkIcon} alt="delete" />
					{t('delete')}
				</Link>
			</div>
		);
	}
}

export default withTranslation()(EditBar);
