import classNames from 'classnames';
import { Component, ReactNode } from 'react';
import styles from './Section.module.scss';

interface ISectionProps {
	children?: ReactNode;
	color?: 'Dimmed' | 'Primary';
	size?: 'Small';
	fullWidth?: boolean;
}

const sizeDefault = {
	SMALL: 'Small',
};

export default class Section extends Component<ISectionProps> {
	getContentClassName() {
		return this.props.fullWidth ? styles.sectionContainerWide : styles.sectionContainer;
	}

	render() {
		const { color, size } = this.props;

		const sectionClassNames = classNames(styles.section, {
			[styles['section' + color]]: color,
			[styles['section' + size]]: size,
		});

		return (
			<div className={sectionClassNames}>
				<div className={this.getContentClassName()}>{this.props.children}</div>
			</div>
		);
	}
}
