import { FC, Fragment, ReactNode } from 'react';

import Footer from '@/components/shared/footers/MainFooter/MainFooter';
import { SubscribeForm } from '@/components/shared/forms/SubscribeForm/SubscribeForm';
import { Modals } from '@/components/shared/modals/Modals';
import BottomNavigation, {
	IBottomNavigationProps,
} from '@/components/shared/navigations/BottomNavigation/BottomNavigation';
import Navigation, { ColorTypeOptions } from '@/components/shared/navigations/CoreNavigation/CoreNavigation';
import { CookieBanner } from '@/components/shared/cookie-banner/CookieBanner';
import SponsorsSection from '@/components/shared/sponsors-section/SponsorsSection';

export interface ILayout {
	colorTypeActive?: ColorTypeOptions;
	colorType?: ColorTypeOptions;
	children?: ReactNode;
	bottomNavigation?: Pick<IBottomNavigationProps, 'leftLink' | 'rightLink'>;
	languagePickerColor?: 'primary' | 'secondary';
}

export const Layout: FC<ILayout> = ({
	colorTypeActive,
	colorType,
	languagePickerColor,
	bottomNavigation,
	children,
}) => {
	return (
		<Fragment>
			<CookieBanner />
			<Modals />
			<Navigation colorTypeActive={colorTypeActive} colorType={colorType} languagePickerColor={languagePickerColor} />
			{children}
			<BottomNavigation {...bottomNavigation} />
			<SubscribeForm />
			<SponsorsSection />
			<Footer />
		</Fragment>
	);
};
