import React, { FC, forwardRef, ElementType, ReactNode, useState, CSSProperties } from 'react';
import classNames from 'classnames';
import styles from './FormInput.module.scss';

interface IFormInputProps {
	children?: ReactNode;
	component?: ElementType;
	className?: string;
	type?: string;
	name?: string;
	placeholder?: string;
	multiline?: boolean;
	showError?: boolean;
	showWarning?: boolean;
	value?: number | string;
	autoComplete?: string;
	rows?: number;
	style?: CSSProperties | undefined;
}

export const FormInput: FC<IFormInputProps> = forwardRef(
	({ children, type, className, showError, showWarning, multiline, component, ...rest }, ref) => {
		const [inputType, setInputType] = useState(type);

		const passwordShowHide = () => {
			setInputType((prev) => (prev === 'text' ? 'password' : 'text'));
		};

		const underlineClassNames = classNames(styles.inputUnderline, {
			[styles.inputUnderlineError]: showError,
			[styles.inputUnderlineWarning]: showWarning,
		});

		const elementClassName = classNames(
			{
				[styles.inputMultiline]: multiline,
			},
			styles.input,
			className
		);

		if (!component) {
			component = multiline ? 'textarea' : 'input';
		}

		const Element = component;

		return (
			<div className={styles.inputWrapper}>
				<Element type={inputType} className={elementClassName} ref={ref} {...rest}>
					{children}
				</Element>
				{inputType === 'password' ? <span className={styles.inputShowHide} onClick={passwordShowHide} /> : null}
				<div className={underlineClassNames} />
			</div>
		);
	}
);
