import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Heading.module.scss';

interface IHeadingProps {
	children?: ReactNode;
	text?: string;
	className?: string;
	textClassName?: string;
	accessoriesClassName?: string;
}

export default class Heading extends Component<IHeadingProps> {
	render() {
		const { children, text = '', className, textClassName, accessoriesClassName } = this.props;

		const combinedClassNames = classNames(styles.heading, className);

		const textClassNames = classNames(styles.headingText, textClassName);

		const accessoriesClassNames = classNames(styles.headingAccessories, accessoriesClassName);

		return (
			<div className={combinedClassNames}>
				<h2 className={textClassNames}>{text}</h2>
				<div className={accessoriesClassNames}>{children}</div>
			</div>
		);
	}
}
