import { withTranslation, WithTranslation } from 'next-i18next';
import { Component } from 'react';
import styles from './SharePanel.module.scss';
import ModalLink from 'next/link';
import { useUser } from '@/hooks/useUser';
import { withUser } from '@/components/shared/utilities/WithUser/WithUser';

interface ISharePanelProps extends WithTranslation, ReturnType<typeof useUser> {}
class SharePanel extends Component<ISharePanelProps> {
	render() {
		const { t, user } = this.props;

		return (
			<div className={styles.panel}>
				<div className={styles.panelContent}>
					<div className={styles.panelCol}>
						<div className={styles.panelItem}>
							<div className={styles.panelItemIcon}>
								<img src={'/assets/icons/heart-crossed.svg'} alt="heart crossed icon"></img>
							</div>
							<div className={styles.panelItemContent}>
								<p className={styles.panelItemDescritpion}>{t('share-your-breakup-story-ima')}</p>
								{user ? (
									<ModalLink href={{ query: { open: 'share-story' } }} shallow={true} className={styles.link}>
										{t('share')}
									</ModalLink>
								) : (
									<ModalLink href={{ query: { open: 'login' } }} shallow={true} className={styles.link}>
										{t('login-to-share')}
									</ModalLink>
								)}
							</div>
						</div>
					</div>
					<div className={styles.panelCol}>
						<div className={styles.panelItem}>
							<div className={styles.panelItemIcon}>
								<img src={'/assets/icons/lock.svg'} alt="lock icon"></img>
							</div>
							<div className={styles.panelItemContent}>
								<p className={styles.panelItemDescritpion}>{t('timelock-your-memory-in-the-v')}</p>
								{user ? (
									<ModalLink href={{ query: { open: 'share-story' } }} shallow={true} className={styles.link}>
										{t('share')}
									</ModalLink>
								) : (
									<ModalLink href={{ query: { open: 'login' } }} shallow={true} className={styles.link}>
										{t('login-to-share')}
									</ModalLink>
								)}
							</div>
						</div>
					</div>
					<div className={styles.panelColLast}>
						<div className={styles.panelItem}>
							<div className={styles.panelItemIcon}>
								<img src={'/assets/icons/donate-lighter.svg'} alt="donate icon"></img>
							</div>
							<div className={styles.panelItemContent}>
								<p className={styles.panelItemDescritpion}>{t('send-your-items-to-the-museum')}</p>
								<ModalLink href={{ query: { open: 'contribute' } }} shallow={true} className={styles.link}>
									{t('contribute-to-collection')}
								</ModalLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(withUser(SharePanel));
