import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Jumbotron.module.scss';

export interface IJumbotronProps {
	children?: React.ReactNode;
	color?: string;
	backgroundImage?: string;
	className?: string;
	maskColor?: string;
	maskClassName?: string;
	contentClassName?: string;
	style?: Record<string, string>;
}

export default class Jumbotron extends Component<IJumbotronProps> {
	static color = {
		PRIMARY: 'Primary',
		PRIMARY_DARK: 'PrimaryDark',
		PRIMARY_DARKER: 'PrimaryDarker',
		SECONDARY: 'Secondary',
		TERNARY: 'Ternary',
		DARK: 'Dark',
	};

	static maskColor = {
		DIMMED: 'Dimmed',
	};

	render() {
		const { color, backgroundImage, className, maskColor, maskClassName, contentClassName, style } = this.props;

		const jumbotronClassNames = classNames(
			styles.jumbotron,
			{
				[styles['jumbotron' + color]]: color,
			},
			className
		);

		const maskClassNames = classNames(
			styles.jumbotronMask,
			{
				[styles['jumbotronMask' + maskColor]]: maskColor,
			},
			maskClassName
		);

		const backgroundImageStyle = backgroundImage
			? {
					backgroundImage: 'url(' + backgroundImage + ')',
					...style,
			  }
			: undefined;

		const contentClassNames = classNames(styles.jumbotronContent, contentClassName);

		return (
			<div className={jumbotronClassNames} style={backgroundImageStyle}>
				<svg width="100%" height="40px" viewBox="0 0 20 10" preserveAspectRatio="none" className={maskClassNames}>
					<polygon points="0,10 20,10 20,0" />
				</svg>
				<div className={contentClassNames}>{this.props.children}</div>
			</div>
		);
	}
}
