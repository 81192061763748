import classNames from 'classnames';
import { withTranslation, WithTranslation } from 'next-i18next';
import { NextRouter, withRouter } from 'next/router';
import { Component } from 'react';

import Author from '@/components/features/posts/Author/Author';
import EditBar from '@/components/features/posts/EditBar/EditBar';
import ImageLoader from '@/components/features/posts/ImageLoader/ImageLoader';
import PostInfo from '@/components/features/posts/PostInfo/PostInfo';
import { LikeButton } from '@/components/features/posts/SocialBar/LikeButton/LikeButton';
import SocialBar from '@/components/features/posts/SocialBar/SocialBar';
import { ICollectionResource } from '@/interfaces/ICollectionResource';
import { getImageUrl, isUsingFallback, size } from '@/utils/attachment';
import { POST_ITEMS_TYPES, POST_ORIENTATION, translatePostItemType } from '@/utils/constants';
import { formatPostedAtDate, formatUnlocksAt, isLocked } from '@/utils/post-date';
import { getCollection } from '@/utils/social-media-share-links';
import { ellipsis, escapeSymbols } from '@/utils/string-util';

import { withUser } from '@/components/shared/utilities/WithUser/WithUser';
import { useUser } from '@/hooks/useUser';
import styles from './PostItem.module.scss';
import { PostItemTypes } from '@/utils/enums';

interface WithRouterProps {
	router: NextRouter;
}

interface IPostItemProps extends WithTranslation, WithRouterProps, ReturnType<typeof useUser> {
	data: ICollectionResource;
	to: string;
	edit?: boolean;
	withActions?: boolean;
	orientation?: string;
	className?: string;
	query?: object;
	push?: () => void;
	favorite?: () => void;
	unfavorite?: () => void;
}

const getFeedUrl = ({ slug, id }: any = {}) => {
	const identifier = slug || id;

	return `/feed/${identifier}`;
};

const getPostActions = (data, edit) => {
	const locked = isLocked(data.unlocksAt);

	if (edit) {
		return <EditBar for={{ id: data.id }} />;
	}

	if (locked) {
		return null;
	}

	return (
		<SocialBar
			likesCount={data.commentsCount}
			commentsCount={data.commentsCount}
			shareLinksCollection={getCollection(getFeedUrl(data), data)}
		/>
	);
};

class PostItem extends Component<IPostItemProps> {
	static orientation = {
		LANDSCAPE: 'Landscape',
		PORTRAIT: 'Portrait',
	};

	goToFeed(to) {
		if (!this.props.edit) {
			this.props.router.push({
				pathname: to,
				query: { ...(this.props.query ? this.props.query : this.props.router.query) },
			});
		}
	}

	render() {
		const { data, to, edit, className, orientation, withActions, t, user } = this.props;

		const showStoryInfo = POST_ITEMS_TYPES[data.type] === PostItemTypes.STORY;
		const locked = isLocked(data.unlocksAt);

		const isUsingDefaultImage = isUsingFallback(data.image, size.MEDIUM);

		const postOrientation = isUsingDefaultImage || locked ? POST_ORIENTATION.VERTICAL : data.orientation;

		const postClassNames = classNames(
			styles.post,
			{
				[styles.postLink]: !locked,
				[styles.postHorizontal]: POST_ORIENTATION.HORIZONTAL === postOrientation,
				[styles['post' + orientation]]: orientation,
			},
			className
		);

		const postWrapperClassNames = classNames(styles.postWrapper, {
			[styles.postWrapperLocked]: locked,
		});

		const mediaClassNames = classNames(styles.postMedia, {
			[styles['postMedia' + orientation]]: orientation,
			[styles.postMediaStory]: showStoryInfo && !locked,
			[styles.postMediaDefault]: isUsingDefaultImage,
			[styles.postMediaLocked]: locked,
		});

		const contentClassNames = classNames(styles.postContent, {
			[styles['postContent' + orientation]]: orientation,
		});

		const footerClassNames = classNames(styles.postFooter, {
			[styles['postFooter' + orientation]]: orientation,
		});

		const postMediaAuthorClassNames = classNames(styles.postMediaAuthor, {
			[styles.postMediaAuthorTransparent]: edit,
		});

		const postImageClassNames = classNames(styles.postImage, {
			[styles.postImageLocked]: locked,
			[styles.postImageDefault]: isUsingDefaultImage,
		});

		// Only unlocked items can be clicked on
		const containerProps = locked ? {} : { onClick: this.goToFeed.bind(this, to) };

		return (
			<div className={postClassNames} {...containerProps}>
				<div className={postWrapperClassNames}>
					<div className={mediaClassNames}>
						{!locked && (
							<ImageLoader
								src={getImageUrl(data.image, size.MEDIUM)}
								preloader={getImageUrl(data.image, size.PRELOADER)}
								className={postImageClassNames}
							/>
						)}
						{locked && (
							<div className={styles.postOverlay}>
								<div className={styles.postOverlayContent}>
									<div className={styles.postLocked}>
										<div className={styles.postLockedIcon}></div>
										<p className={styles.postLockedText}>{t('locked-until')}</p>
										<p className={styles.postLockedText}>{formatUnlocksAt(data.unlocksAt)}</p>
									</div>
								</div>
							</div>
						)}
						{edit && (
							<div className={styles.postOverlayInvert}>
								<div className={styles.postOverlayContent}>
									<div className={styles.postInReview}>
										<p className={styles.postInReviewText}>{t('the-story-is-in-a-review')}</p>
									</div>
								</div>
							</div>
						)}
						{showStoryInfo && (
							<div className={postMediaAuthorClassNames}>
								<Author
									initials={data.initials}
									label={`${t('posted')} ${formatPostedAtDate(data.createdAt)}`}
									locked={locked}
								/>
							</div>
						)}
					</div>
					{!locked && (
						<div className={contentClassNames}>
							{!showStoryInfo ? (
								<div className={styles.postType}>{translatePostItemType(POST_ITEMS_TYPES[data.type])}</div>
							) : // <div className={styles.postType}>{POST_ITEMS_TYPES[data.type]}</div>
							null}
							<h3 className={styles.postTitle}>{data.title}</h3>
							<PostInfo className={styles.postInfo} data={data} />
							<div className={styles.postDesc}>{ellipsis(escapeSymbols(data.content))}</div>
						</div>
					)}
					{(edit || !locked) && (
						<div className={footerClassNames}>
							{withActions && user && <LikeButton faveable={data} />}
							{getPostActions(data, edit)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default withTranslation()(withRouter(withUser(PostItem)));
